<template>
  <b-card>
    <h4 class="mb-0">
      Edit Prestasi
    </h4>
    <validation-observer ref="validUpdate">
      <b-form class="mt-1" @submit.prevent="validationForm">
        <b-row>
          <b-col md="6">
            <validation-provider #default="{ errors }" name="student_id" rules="required">
              <b-form-group label="Siswa" label-for="student_id" :state="errors.length > 0 ? false : null">
                <v-select id="student_id" v-model="dataStudent.student_id" :reduce="(student_id) => student_id.id"
                  placeholder="Pilih Siswa" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="listSiswa"
                  label="name" disabled />
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <b-form-group label="Tanggal" label-for="date">
              <validation-provider #default="{ errors }" name="Tanggal" rules="required">
                <b-form-input id="date" v-model="dataStudent.date" :state="errors.length > 0 ? false : null"
                  placeholder="Tanggal" type="date" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Event" label-for="event">
              <validation-provider #default="{ errors }" name="Nama Event" rules="required">
                <b-form-input id="event" v-model="dataStudent.event" :state="errors.length > 0 ? false : null"
                  placeholder="Nama Event" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">

            <validation-provider #default="{ errors }" name="category" rules="required">
              <b-form-group label="Kategori" label-for="category" :state="errors.length > 0 ? false : null">
                <v-select id="category" v-model="dataStudent.category" :reduce="(category) => category.id"
                  placeholder="Pilih Kategori" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="listPerformance" label="performance_type" />
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <b-form-group label="Prestasi" label-for="achivement">
              <validation-provider #default="{ errors }" name="achivement" rules="required">
                <b-form-input id="achivement" v-model="dataStudent.achivement" :state="errors.length > 0 ? false : null"
                  placeholder="Prestasi" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="text-right">
          <!-- submit and reset -->
          <b-col>
            <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="reset" variant="outline-secondary" class="mr-1"
              @click="() => $router.go(-1)">
              Cancel
            </b-button>
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" type="submit" variant="primary">
              Update
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <!-- <b-container> -->

    <!-- </b-container> -->
  </b-card>
</template>

<script>
import {
  // BContainer,
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  // BFormCheckbox,
  BFormInvalidFeedback,
  BForm,
  BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import dataCountries from '@/assets/json/countries.json'

export default {
  name: 'DataSiswaEdit',
  components: {
    ValidationProvider,
    ValidationObserver,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    // BContainer,
    // BFormCheckbox,
    BForm,
    BButton,
    vSelect,
    BFormInvalidFeedback,
  },
  directives: {
    Ripple,
  },
  data: () => ({
    required,
    name: '',
    dataStudent: {
      student_id: '',
      date: '',
      event: '',
      category: '',
      achivement: '',
    },
    listSiswa: [],
    listPerformance: [],
  }),
  computed: {

  },
  created() {
    this.getStudentDetail()
    this.getSiswa(),
      this.getperformance()
  },
  methods: {
    async getperformance() {
      try {
        const response = await this.$http.get('/performancetypes')
        this.listPerformance = response.data.data
      } catch (err) {
        this.showToast('danger', 'top-center', 'Get Presences Not Success')
        console.log(err)
      }
    },
    async getSiswa() {
      try {
        const response = await this.$http.get('/students')
        this.listSiswa = response.data.data
      } catch (err) {
        this.showToast('danger', 'top-center', 'Get Presences Not Success')
        console.log(err)
      }
    },
    async getStudentDetail() {
      try {
        const studentId = this.$route.params.id
        const response = await this.$http.get(`/achivements/${studentId}`)
        this.fillStudentData(response.data.data)
      } catch (err) {
        console.log(err)
      }
    },
    fillStudentData(data) {
      this.dataStudent.student_id = data.student_id
      this.dataStudent.event = data.event
      this.dataStudent.category = data.category
      this.dataStudent.achivement = data.achivement
      this.dataStudent.date = data.date.substr(0, 10)
    },
    validationForm() {
      this.$refs.validUpdate.validate().then(async success => {
        if (success) {
          // eslint-disable-next-line
          try {
            const studentId = this.$route.params.id
            const dataFormStudent = new FormData()
            Object.keys(this.dataStudent).forEach(key => {
              dataFormStudent.append(key, this.dataStudent[key])
            })
            dataFormStudent.append('_method', 'PUT')
            this.$http.put(`/achivements/${studentId}`, dataFormStudent, {
              headers: {
                'content-type': 'multipart/form-data',
              },
            })
            // alertnya()
          } catch (err) {
            console.log(err)
          }
        }

        // function alertnya() {
        //   alert('form submitted!')
        // }
      })
    },
  },
}
</script>

<style lang="scss">
@import "~@core/scss/vue/libs/vue-select.scss";
</style>
